<template>
	<div :class="layoutContainerClass" @click="onDocumentClick">
		<AppTopBar :horizontal="menuMode === 'horizontal'" :topbarMenuActive="topbarMenuActive"
			:activeTopbarItem="activeTopbarItem" :mobileTopbarActive="mobileTopbarActive"
			@topbar-mobileactive="onTopbarMobileButtonClick" @menubutton-click="onMenuButtonClick"
			@topbar-menubutton-click="onTopbarMenuButtonClick" @topbaritem-click="onTopbarItemClick"
			@rightpanel-button-click="onRightPanelButtonClick" @abrirModalAlterarSenha="abrirModalAlterarSenha">
		</AppTopBar>

		<div class="menu-wrapper">
			<div class="layout-menu-container" @click="onMenuClick">
				<AppInlineMenu v-if="inlineMenuPosition === 'top' || inlineMenuPosition === 'both'"
					v-model:active="inlineMenuTopActive" @change-inlinemenu="onChangeInlineMenu" inlineMenuKey="top"
					:menuMode="menuMode" @abrirModalAlterarSenha="abrirModalAlterarSenha"></AppInlineMenu>
				<AppMenu :model="menu" :menuMode="menuMode" :active="menuActive" :mobileMenuActive="mobileMenuActive"
					:isSlimOrHorItemClick="isSlimOrHorItemClick" @menuitem-click="onMenuItemClick"
					@root-menuitem-click="onRootMenuItemClick"></AppMenu>
				<AppInlineMenu v-if="inlineMenuPosition === 'bottom' || inlineMenuPosition === 'both'"
					v-model:active="inlineMenuBottomActive" @change-inlinemenu="onChangeInlineMenu" inlineMenuKey="bottom"
					:menuMode="menuMode" @abrirModalAlterarSenha="abrirModalAlterarSenha"></AppInlineMenu>
			</div>
		</div>

		<div class="layout-main">

			<AppBreadcrumb></AppBreadcrumb>

			<div class="layout-content">
				<!-- <router-view @carregarFavoritos="carregarFavoritos" />-->
				<router-view />
			</div>

			<AppFooter :layoutMode="layoutMode" />
		</div>

		<!-- <AppRightPanel :expanded="rightPanelActive" @content-click="onRightPanelClick" @hide="onHideClick"></AppRightPanel> -->
		<div v-if="mobileMenuActive" class="layout-mask modal-in"></div>

		<CooperadoAlterarSenhaModal :visivel="alterarSenhaDialog" @fechar="fecharModalAlterarSenha()" />
	</div>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb.vue';
import AppFooter from './AppFooter.vue';
import AppInlineMenu from './AppInlineMenu.vue';
import AppMenu from './AppMenu.vue';
import AppRightPanel from './AppRightPanel.vue';
import AppTopBar from './AppTopbar.vue';
import CooperadoAlterarSenhaModal from './components/admin/CooperadoAlterarSenhaModal.vue';
import EventBus from './event-bus';
import FavoritoService from './service/FavoritoService';
import StorageService from './service/StorageService';

export default {
	emits: ['layout-mode-change', 'menu-theme', 'menuTheme', 'topbar-theme', 'topbarTheme', 'layoutModeChange', 'carregarFavoritos'],
	props: {
		topbarTheme: String,
		menuTheme: String,
		layoutMode: String
	},
	data() {
		return {
			d_topbarTheme: this.topbarTheme,
			d_menuTheme: this.menuTheme,
			d_layoutMode: this.layoutMode,
			mobileTopbarActive: false,
			mobileMenuActive: false,
			menuMode: 'static',
			inlineMenuClick: false,
			inlineMenuPosition: this.currentUser != null ? this.currentUser.menuPosition : 'top',
			inlineMenuTopActive: false,
			inlineMenuBottomActive: false,
			overlayMenuActive: false,
			rotateMenuButton: false,
			topbarMenuActive: false,
			activeTopbarItem: null,
			isSlimOrHorItemClick: false,
			darkMenu: false,
			theme: 'green',
			themes: [
				{ name: 'indigo', color: '#2f8ee5' },
				{ name: 'pink', color: '#E91E63' },
				{ name: 'purple', color: '#9C27B0' },
				{ name: 'deeppurple', color: '#673AB7' },
				{ name: 'blue', color: '#2196F3' },
				{ name: 'lightblue', color: '#03A9F4' },
				{ name: 'cyan', color: '#00BCD4' },
				{ name: 'teal', color: '#009688' },
				{ name: 'green', color: '#4CAF50' },
				{ name: 'lightgreen', color: '#8BC34A' },
				{ name: 'lime', color: '#CDDC39' },
				{ name: 'yellow', color: '#FFEB3B' },
				{ name: 'amber', color: '#FFC107' },
				{ name: 'orange', color: '#FF9800' },
				{ name: 'deeporange', color: '#FF5722' },
				{ name: 'brown', color: '#795548' },
				{ name: 'bluegrey', color: '#607D8B' }
			],
			menuThemes: [
				{ name: 'light', color: '#FDFEFF' },
				{ name: 'dark', color: '#434B54' },
				{ name: 'green', color: '#2E7D32' }
			],
			topbarThemes: [
				{ name: 'lightblue', color: '#2E88FF' },
				{ name: 'dark', color: '#363636' },
				{ name: 'blue', color: '#1565C0' },
				{ name: 'deeppurple', color: '#4527A0' },
				{ name: 'purple', color: '#6A1B9A' },
				{ name: 'cyan', color: '#0097A7' },
				{ name: 'teal', color: '#00796B' },
				{ name: 'green', color: '#43A047' },
				{ name: 'grey', color: '#616161' },
				{ name: 'bluegrey', color: '#546E7A' },
				{ name: 'indigo', color: '#3F51B5' }
			],
			rightPanelActive: false,
			menuActive: true,
			menu: [
				{
					label: 'Favoritos',
					icon: 'pi pi-fw pi-home',
					items: []
				},
				{
					label: 'Cadastro',
					icon: 'pi pi-fw pi-file',
					items: [
						{
							label: 'Cooperado',
							icon: 'pi pi-fw pi-check-square',
							to: '/cooperados'
						},
						{
							label: 'Usuário',
							icon: 'pi pi-fw pi-dollar',
							to: '/usuarios'
						},
						{
							label: 'Funcionalidade',
							icon: 'pi pi-fw pi-cog',
							to: '/funcionalidades'
						}, {
							label: 'Banner',
							icon: 'pi pi-fw pi-file',
							to: '/banners'
						},
						{
							label: 'Dúvidas Frequentes',
							icon: 'pi pi-fw pi-question',
							to: '/adm-duvidas-frequentes'
						},
						{
							label: 'Imagem de Fundo',
							icon: 'pi pi-fw pi-image',
							to: '/imagem-fundo'
						},
					]
				},
				{
					label: 'Transações comerciais',
					icon: 'pi pi-fw pi-file',
					items: [
						{
							label: 'Produção',
							icon: 'pi pi-fw pi-window-minimize',
							items: [
								{
									label: 'Entrega de Produção',
									icon: 'pi pi-fw pi-copy',
									to: '/entrega-producao'
								},
								{
									label: 'Produtos a Fixar',
									icon: 'pi pi-fw pi-book',
									to: '/produtos-armazenados'
								},
								{
									label: 'Histórico de Venda',
									icon: 'pi pi-fw pi-book',
									to: '/historico-venda'
								},
								{
									label: 'Taxa de Armazenagem',
									icon: 'pi pi-fw pi-th-large',
									to: '/taxa-armazenagem'
								},
							]
						},
						{
							label: 'Contrato',
							icon: 'pi pi-fw pi-file',
							to: '/contratos'
						},
						{
							label: 'Insumos',
							icon: 'pi pi-fw pi-globe',
							items: [
								{
									label: 'Pedido',
									icon: 'pi pi-fw pi-copy',
									to: '/pedidos'
								},
								{
									label: ' Histórico de Pedido',
									icon: 'pi pi-fw pi-book',
									to: '/historico-pedido'
								},
								{
									label: ' Produtos Disponíveis',
									icon: 'pi pi-fw pi-book',
									to: '/produtos-disponiveis'
								},
							]
						},
						{
							label: 'Nota fiscal',
							icon: 'pi pi-fw pi-file',
							to: '/notas-fiscais'
						},
						{
							label: 'Preços',
							icon: 'pi pi-fw pi-money-bill',
							items: [
								{
									label: 'Preço da Integrada',
									icon: 'pi pi-fw pi-money-bill',
									to: '/precos-integrada'
								},
								{
									label: 'Histórico de Preço',
									icon: 'pi pi-fw pi-book',
									to: '/historico-preco'
								},
							]
						},
					]
				},
				{
					label: 'Finanças',
					icon: 'pi pi-fw pi-search',
					items: [
						{
							label: 'Contas a Pagar',
							icon: 'pi pi-fw pi-dollar',
							to: '/contas-pagar'
						},
						{
							label: 'Contas a Receber',
							icon: 'pi pi-fw pi-dollar',
							to: '/contas-receber'
						},
						{
							label: 'Sobra',
							icon: 'pi pi-fw pi-money-bill',
							to: '/sobras'
						},
						{
							label: 'Informe de Rendimento',
							icon: 'pi pi-fw pi-dollar',
							to: '/informe-rendimentos'
						},
						{
							label: 'Capital Social',
							icon: 'pi pi-fw pi-dollar',
							to: '/capital-social'
						},
					]
				},
				{
					label: 'Dossiê',
					icon: 'pi pi-fw pi-download',
					items: [
						{
							label: 'Estação Meteorológica',
							icon: 'pi pi-fw pi-chart-bar',
							to: '/historico-chuvas'
						},
						{
							label: 'Dossiê Agrícola',
							icon: 'pi pi-fw pi-file',
							to: '/dossie-agricola'
						},
						{
							label: ' Atendimento',
							icon: 'pi pi-fw pi-calendar',
							to: '/atendimentos'
						},
					]
				},
				{
					label: 'Cooperado',
					icon: 'pi pi-fw pi-download',
					items: [
						{
							label: 'Dados Pessoais',
							icon: 'pi pi-fw pi-user',
							to: '/dados-pessoais'
						},
						{
							label: 'Plano de Saúde',
							icon: 'pi pi-fw pi-heart-fill',
							to: '/plano-saude'
						},
						{
							label: 'Imóveis Cadastrados',
							icon: 'pi pi-fw pi-home',
							to: '/imoveis-cadastrados'
						},
					]
				},
				{
					label: 'Relação com o cooperado',
					icon: 'pi pi-fw pi-cog',
					items: [
						{
							label: 'Nossas Unidades',
							icon: 'pi pi-fw pi-tags',
							to: '/estabelecimentos'
						},
						{
							label: 'Estatuto Social',
							icon: 'pi pi-fw pi-file-pdf',
							to: '/estatuto-social'
						}
					]
				},
				{
					label: 'Classificados',
					icon: 'pi pi-fw pi-cog',
					items: [
						{
							label: 'Classificado',
							icon: 'pi pi-fw pi-tags',
							to: '/classificados'
						},
					]
				},
				{
					label: 'Outros',
					icon: 'pi pi-fw pi-cog',
					items: [
						{
							label: 'Histórico de Acesso',
							icon: 'pi pi-fw pi-undo',
							to: '/historico-acesso'
						},
						{
							label: 'Extrato Resumido',
							icon: 'pi pi-fw pi-check-square',
							to: '/extrato-resumido'
						},
						{
							label: 'Favoritos',
							icon: 'pi pi-fw pi-star',
							to: '/manter-favoritos'
						},

					]
				}
			],

			alterarSenhaDialog: false,
		};
	},
	watch: {
		$route() {
			this.menuActive = this.isStatic() && !this.isMobile();
			this.$toast.removeAllGroups();
		},
		topbarTheme(newValue) {
			this.d_topbarTheme = newValue;
		},
		menuTheme(newValue) {
			this.d_menuTheme = newValue;
		},
		layoutMode(newValue) {
			this.d_layoutMode = newValue;
		}
	},
	methods: {
		onDocumentClick() {
			if (!this.topbarItemClick) {
				this.activeTopbarItem = null;
				this.topbarMenuActive = true;
			}

			if (!this.menuClick) {
				if (this.isHorizontal() || this.isSlim()) {
					this.menuActive = false;
					this.isSlimOrHorItemClick = false;
					EventBus.emit('reset-active-index');
				}

				if (this.isOverlay()) {
					this.menuActive = false;
				}

				this.hideOverlayMenu();
				this.unblockBodyScroll();
			}

			if (!this.rightPanelClick) {
				this.rightPanelActive = false;
			}

			if (!this.inlineMenuClick) {
				this.inlineMenuTopActive = false;
				this.inlineMenuBottomActive = false;
			}

			this.topbarItemClick = false;
			this.menuClick = false;
			this.rightPanelClick = false;
			this.inlineMenuClick = false;
		},
		isHorizontal() {
			return this.menuMode === 'horizontal';
		},
		isSlim() {
			return this.menuMode === 'slim';
		},
		isOverlay() {
			return this.menuMode === 'overlay';
		},
		isStatic() {
			return this.menuMode === 'static';
		},
		isDesktop() {
			return window.innerWidth > 991;
		},
		isMobile() {
			return window.innerWidth <= 991;
		},
		hideOverlayMenu() {
			this.rotateMenuButton = false;
			this.overlayMenuActive = false;
			this.mobileMenuActive = false;
		},
		onMenuButtonClick(event) {
			this.menuClick = true;
			this.menuActive = !this.menuActive;
			this.topbarMenuActive = false;
			this.topbarRightClick = true;
			this.rotateMenuButton = !this.rotateMenuButton;
			this.topbarMenuActive = false;

			if (!this.isDesktop()) {
				this.mobileMenuActive = !this.mobileMenuActive;
				if (this.mobileMenuActive) {
					this.blockBodyScroll();
				} else {
					this.unblockBodyScroll();
				}
			}

			event.preventDefault();
		},
		onTopbarMenuButtonClick(event) {
			this.topbarItemClick = true;
			this.topbarMenuActive = !this.topbarMenuActive;
			this.hideOverlayMenu();
			event.preventDefault();
		},
		onTopbarItemClick(event) {
			this.topbarItemClick = true;

			if (this.activeTopbarItem === event.item)
				this.activeTopbarItem = null;
			else
				this.activeTopbarItem = event.item;

			event.originalEvent.preventDefault();
		},
		onTopbarMobileButtonClick(event) {
			this.mobileTopbarActive = !this.mobileTopbarActive;
			event.preventDefault();
		},
		onRightPanelButtonClick(event) {
			this.rightPanelClick = true;
			this.rightPanelActive = !this.rightPanelActive;

			event.preventDefault();
		},
		onRightPanelClick() {
			this.rightPanelClick = true;
		},
		onHideClick(expanded) {
			this.rightPanelActive = expanded;
		},
		onMenuClick() {
			this.menuClick = true;
		},
		onRootMenuItemClick(event) {
			if (event.isSameIndex) {
				this.isSlimOrHorItemClick = false;
			}
			else {
				this.isSlimOrHorItemClick = true;
			}
			this.menuActive = !this.menuActive;
		},
		onMenuItemClick(event) {
			if (!event.item.items) {
				this.isSlimOrHorItemClick = false;
				this.hideOverlayMenu();
				EventBus.emit('reset-active-index');
			}

			if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
				this.menuActive = false;
			}
		},
		onMenuModeChange(menuMode) {
			this.menuMode = menuMode;
			this.overlayMenuActive = false;

			if (menuMode === 'static') {
				this.menuActive = true;
			}

			if (menuMode === 'horizontal') {
				this.inlineMenuPosition = 'bottom';
			}
		},
		onLayoutModeChange(menuColor) {
			this.$emit('layout-mode-change', menuColor);

			const layoutLink = document.getElementById('layout-css');
			const layoutHref = 'layout/css/layout-' + menuColor + '.css';
			this.replaceLink(layoutLink, layoutHref);

			const themeLink = document.getElementById('theme-css');
			const urlTokens = themeLink.getAttribute('href').split('/');
			urlTokens[urlTokens.length - 1] = 'theme-' + menuColor + '.css';
			const newURL = urlTokens.join('/');

			this.replaceLink(themeLink, newURL, () => {
				this.$appState.isNewThemeLoaded = true;
			});
		},
		onInlineMenuPositionChange(position) {
			this.inlineMenuPosition = position;
		},
		onChangeInlineMenu(e, key) {
			if (key === 'top') {
				if (this.inlineMenuBottomActive) {
					this.inlineMenuBottomActive = false;
				}
				this.inlineMenuTopActive = !this.inlineMenuTopActive;
			}
			if (key === 'bottom') {
				if (this.inlineMenuTopActive) {
					this.inlineMenuTopActive = false;
				}
				this.inlineMenuBottomActive = !this.inlineMenuBottomActive;
			}

			this.inlineMenuClick = true;

		},
		changeTheme(theme) {
			this.theme = theme;
			this.changeStyleSheetUrl('theme-css', theme);
		},
		onTopbarThemeChange(theme) {
			this.$emit('topbar-theme', theme);
		},
		onMenuTheme(menuTheme) {
			this.$emit('menu-theme', menuTheme);
		},
		changeStyleSheetUrl(id, value) {
			const element = document.getElementById(id);
			const urlTokens = element.getAttribute('href').split('/');
			urlTokens[urlTokens.length - 2] = value;
			const newURL = urlTokens.join('/');
			this.replaceLink(element, newURL);
		},
		replaceLink(linkElement, href, callback) {
			const id = linkElement.getAttribute('id');
			const cloneLinkElement = linkElement.cloneNode(true);

			cloneLinkElement.setAttribute('href', href);
			cloneLinkElement.setAttribute('id', id + '-clone');

			linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

			cloneLinkElement.addEventListener('load', () => {
				linkElement.remove();
				cloneLinkElement.setAttribute('id', id);

				if (callback) {
					callback();
				}
			});
		},
		blockBodyScroll() {
			if (document.body.classList) {
				document.body.classList.add('blocked-scroll');
			} else {
				document.body.className += ' blocked-scroll';
			}
		},
		unblockBodyScroll() {
			if (document.body.classList) {
				document.body.classList.remove('blocked-scroll');
			} else {
				document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
					'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
			}
		},

		carregarFavoritos() {
			const posicaoFavoritos = 0;
			this.menu[posicaoFavoritos].items = [
				{ label: 'Início', icon: 'pi pi-fw pi-home', to: '/' },
			];

			if (this.currentUser && this.currentUser.matricula) {
				FavoritoService.listar(this.currentUser.matricula).then(({ data }) => {
					for (let favorito of data) {
						this.menu[posicaoFavoritos].items.push({
							label: favorito.funcionalidade.nome,
							icon: 'pi pi-fw pi-star',
							to: '/' + favorito.funcionalidade.urlVue
						});
					}
				});
			}
		},

		removerMenuAdm() {
			const administrador = this.currentUser.administrador;

			if (!administrador) {
				//Caso seja um cooperado, então não exibe o menu Cadastro
				// this.menu.splice(1, 1);
				this.menu = this.menu.filter(m => m.label.toUpperCase() != 'CADASTRO');
			}
			else {
				//Caso seja um colaborador, então exiba o menu Cadastro
				if (StorageService.getAdmin().administrador) {
					//Caso seja um ADMIN, então exiba o menu Cadastro completo
					//É tipo_usuario = ADMIN

				}
				else {
					//Caso seja um colaborador do tipo Regional, estão não exiba o menu Funcionalidade e Imagem de Fundo.
					//É tipo_usuario = REGIONAL
					let menu2 = this.filtrarItensPorLabel('Funcionalidade', 'Imagem de Fundo');
					this.menu = menu2;

				}
			}

		},

		//Em 21/12/2023 AIP-111 (Ocultar o menu Funcionalidades do colaborador tipo Regional)
		filtrarItensPorLabel(labelExcluida1, labelExcluida2) {

			let itensFiltrados = this.menu.map(item => {
				// Filtra os subitens com base nas labels especificadas
				let subItensFiltrados = item.items.filter(subItem => {
					return subItem.label !== labelExcluida1 && subItem.label !== labelExcluida2;
				});

				// Atualiza os subitens mantendo os pais
				return {
					...item,
					items: subItensFiltrados
				};
			});

			return itensFiltrados;
		},

		carregarOptions() {
			this.onMenuTheme(this.currentUser?.corMenu || 'light');
			this.onMenuModeChange(this.currentUser?.menu || 'static');
			this.onLayoutModeChange(this.currentUser?.tema || 'light');
			this.changeTheme(this.currentUser?.corComponentes || 'green');
		},

		abrirModalAlterarSenha() {
			this.alterarSenhaDialog = true;
		},

		fecharModalAlterarSenha() {
			this.alterarSenhaDialog = false;
		},
	},
	created() {
		this.carregarOptions();
		this.carregarFavoritos();
		this.removerMenuAdm();
	},
	computed: {
		layoutContainerClass() {
			return [
				'layout-wrapper',
				'layout-menu-' + this.menuTheme + ' layout-topbar-' + this.topbarTheme,
				{
					'layout-menu-static': this.menuMode === 'static',
					'layout-menu-overlay': this.menuMode === 'overlay',
					'layout-menu-overlay-active': this.overlayMenuActive,
					'layout-menu-slim': this.menuMode === 'slim',
					'layout-menu-horizontal': this.menuMode === 'horizontal',
					'layout-menu-active': this.menuActive,
					'layout-menu-mobile-active': this.mobileMenuActive,
					'layout-topbar-mobile-active': this.mobileTopbarActive,
					'layout-rightmenu-active': this.rightPanelActive,
					'layout-rtl': this.$appState.RTL,
					'p-input-filled': this.$primevue.config.inputStyle === 'filled',
					'p-ripple-disabled': this.$primevue.config.ripple === false
				}
			];
		},

		currentUser() {
			const user = this.$store.state.auth.user || {};
			return StorageService.validateSystemOptions(user);
		}
	},
	components: {
		"AppTopBar": AppTopBar,
		"AppInlineMenu": AppInlineMenu,
		"AppRightPanel": AppRightPanel,
		"AppMenu": AppMenu,
		"AppFooter": AppFooter,
		"AppBreadcrumb": AppBreadcrumb,
		CooperadoAlterarSenhaModal
	}
};
</script>

<style lang="scss">
@import './App.scss';
</style>
