import PortalCooperadoUtils from "../utilities/PortalCooperadoUtils";

const CONTAS_PAGAR_API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/`;

class PegarVersaoService {
  async pegarVersao() {
    const response = await fetch(CONTAS_PAGAR_API_BASE_URL + RESOURCE_V1 + `pegarVersao/`);
    const data = await response.json();
    return data;
  }
}

export default new PegarVersaoService();
