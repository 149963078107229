<template>
  <div id="ImagemFundo" class="force-mobile-body flex flex-column"
    v-bind:style="{ 'background-image': 'url(data:image/png;base64,' + img + ')', 'background-size': 'cover' }">
    <div class="align-self-center mt-auto mb-auto">
      <div class="force-mobile-panel card flex flex-column">
        <h5 class="force-mobile-header px-3 py-1 text-center">Experiência Mobile</h5>
        <div class="force-mobile-detail mb-6 px-6 text-center">
          Para uma melhor experiência, use nosso aplicativo. Baixe agora!
        </div>

        <Button class="submit-button" label="Abrir App" @click="openApp"></Button>

        <div class="col-12 dv-stores">
          <div class="col-12 ">
            <a href="https://play.google.com/store/apps/details?id=com.ionicframework.myapp304484" target="_blank">
              <img src="layout/images/login/google_play_download.png" alt="" class="img-store" />
            </a>
          </div>
          <div class="col-12 ">
            <a href="https://geo.itunes.apple.com/br/app/portal-do-cooperado-integrada/id1064157472?mt=8"
              target="_blank">
              <img src="layout/images/login/app_store_download.png" alt="" class="img-store" />
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import ImagemFundoService from '../service/ImagemFundo';
export default {
  data() {
    return {
      img: '',
    };
  },
  mounted() {
    this.recuperarImagemFundo();
  },
  methods: {
    openApp() {
      const appLink = 'integrada-app://open-app';
      window.location.href = appLink;
    },
    async recuperarImagemFundo() {
      ImagemFundoService.recuperarImagemFundo("site")
        .then(
          (response) => {
            this.img = response.data.base64;
          },
          (error) => {
            console.log("Erro ao recuperar a imagem de fundo: ", error);
          }
        );
    }
  }
};
</script>

<style scoped>
.force-mobile-body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.force-mobile-panel {
  opacity: 0.9;
  max-width: 400px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.force-mobile-header {
  background: rgb(76, 146, 83);
  color: white;
  border-radius: 10px 10px 0 0;
}

.force-mobile-detail {
  text-align: center;
  color: #333;
}

.submit-button {
  padding: 8px;
  margin-top: 16px;
  align-self: center;
}

.img-store {
  width: 100%;
  margin-top: 16px;
}

.dv-stores {
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 150px;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}
</style>