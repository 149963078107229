<template>
    <Toast />
    <div id="ImagemFundo" class="pages-body login-page flex flex-column"
        v-bind:style="{ 'background-image': 'url(data:image/png;base64,' + img + ')', 'background-size': 'cover' }">
        <!-- <img :src="`data:image/png;base64,${img}`" /> -->
        <div class="align-self-center mt-auto mb-auto">
            <div class="pages-panel card flex flex-column">
                <div class="pages-header px-3 py-1">
                    <div class="grid col-12">
                        <div class="col-12">
                            <img src="layout/images/login/logo_portal_branca_login.png" alt="">
                        </div>
                    </div>
                </div>

                <h4>Bem vindo.</h4>

                <div class="pages-detail mb-6 px-6">Informe seus dados de usuário para acessar o portal.</div>

                <Form @submit="handleLogin" :validation-schema="schema">
                    <div class="input-panel flex flex-column px-3">
                        <div class="dv-login">

                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-envelope"></i>
                                </span>
                                <span class="p-float-label">
                                    <Field name="username" class="p-inputtext p-component" placeholder="Matricula"
                                        @blur="limparMensagem()" />
                                    <ErrorMessage name="username" class="error-feedback alert-danger" />
                                </span>
                            </div>

                            <div class="p-inputgroup mt-3">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-lock"></i>
                                </span>
                                <span class="p-float-label">
                                    <Field name="password" class="p-inputtext p-component" type="password"
                                        placeholder="Senha" @blur="limparMensagem()" />
                                    <ErrorMessage name="password" class="error-feedback alert-danger" />
                                </span>
                            </div>

                            <div v-if="isAdministrador" class="p-inputgroup mt-3">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-user"></i>
                                </span>
                                <span class="p-float-label">
                                    <AlterarCooperado placeholder="Matrícula ou nome do cooperado"
                                        @mensagemErro="erroColaborador" :dropdown="true" />
                                </span>
                            </div>

                            <g-recaptcha :dataSitekey="siteKey" dataLanguage="pt-BR" :dataCallback="callback"
                                v-if="nodeEnv == 'production'" />

                            <button class="p-button p-component mt-6" :disabled="loading">
                                <i class="pi pi-sign-in" style="margin-right: 10px;" />
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>ENTRAR</span>
                            </button>
                            <div class="form-group">
                                <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
                            </div>
                        </div>



                        <div class="grid">
                            <div class="col-6">
                                <div class="dv-cadastro col-12">
                                    <a href="/#/novo-cadastro">Cadastre-se</a>
                                </div>
                                <div class="col-12 dv-resgate-senha mt-1">
                                    <a @click="abrirRecuperarSenhaModal">Esqueceu a senha?</a>
                                </div>
                                <div class="col-12 dv-google">
                                    <a href="https://play.google.com/store/apps/details?id=com.ionicframework.myapp304484"
                                        target="_blank">
                                        <img src="layout/images/login/google_play_download.png" alt=""
                                            class="img-app-download" />
                                    </a>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="col-12 dv-site">
                                    <a style="font-weight: 800;"
                                        href="http://www.integrada.coop.br">www.integrada.coop.br</a>
                                </div>
                                <div class="col-12 dv-unidade">
                                    <a style="font-weight: 800;" @click="abrirNossasUnidadesModal">Nossas unidades</a>
                                </div>
                                <div class="col-12 dv-app-store">
                                    <a href="https://geo.itunes.apple.com/br/app/portal-do-cooperado-integrada/id1064157472?mt=8"
                                        target="_blank">
                                        <img src="layout/images/login/app_store_download.png" alt=""
                                            class="img-app-download" />
                                    </a>
                                </div>
                            </div>
                            <div class="col-12" style="text-align: center;">
                                <span style="color: #6C6C6C;">{{ version }}</span>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
        <RecuperarSenhaModal :visivelProp="mostrarRecuperarSenhaModal" @fechar="fecharModalRecuperarSenha" />
        <NossasUnidadesModal :visivelProp="mostrarNossasUnidadesModal" @fechar="fecharNossasUnidadesModal" />
    </div>

</template>

<script>
import { ErrorMessage, Field, Form } from "vee-validate";
import * as yup from "yup";
import AlterarCooperado from "../components/AlterarCooperado.vue";
import NossasUnidadesModal from '../components/admin/NossasUnidadesModal.vue';
import RecuperarSenhaModal from '../components/admin/RecuperarSenhaModal.vue';
import HistoricoAcessoService from "../service/HistoricoAcessoService";
import ImagemFundoService from '../service/ImagemFundo';
import PegarVersao from "../service/PegarVersao";
import gRecaptcha from '../utilities/Recaptcha.vue';

export default {
    name: "Login",
    components: {
        Form,
        Field,
        ErrorMessage,
        AlterarCooperado,
        RecuperarSenhaModal,
        NossasUnidadesModal,
        gRecaptcha,
    },
    data() {
        const schema = yup.object().shape({
            username: yup.string().required("Campo obrigatório!"),
            password: yup.string().required("Campo obrigatório!"),
            //message: yup.string().required(""),
        });

        return {
            loading: false,
            message: "",
            schema,

            isAdministrador: false,
            nodeEnv: process.env.NODE_ENV,
            version: "",
            mostrarRecuperarSenhaModal: false,
            mostrarNossasUnidadesModal: false,
            ip: '',
            tokenCaptcha: '',
            siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
            img: '',
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    mounted() {
        this.recuperarImagemFundo();
    },
    created() {
        if (this.loggedIn) {
            this.$router.push("/login");
        }
        this.getIp();
        this.handleGetVersion();
    },
    methods: {
        limparMensagem() {
            this.message = "";
        },

        async handleGetVersion() {
            const data = await PegarVersao.pegarVersao();
            if (!data || !data?.environment) {
                this.version = "";
                return;
            }
            const unAvaliable = "Não disponível";
            this.version = `Versão: ${process.env.VUE_APP_ROOT_VERSION || unAvaliable} / Servidor: ${data.version || unAvaliable} / Ambiente: ${data.environment || unAvaliable}`;
        },

        handleLogin(user) {

            if (this.tokenCaptcha == '' && this.nodeEnv == 'production') {
                this.abrirToast('error', 'Falha', 'Captcha inválido', 3000);
                return;
            }

            this.loading = true;

            this.$store.dispatch("auth/login", user).then(
                (response) => {
                    if (response.tipoUsuarioPortal == 'ADMIN'
                        || response.tipoUsuarioPortal == 'REGIONAL') {
                        this.isAdministrador = true;
                    } else {
                        let historicoAcesso = {
                            idCooperado: response.matricula,
                            web: true,
                            ip: this.ip,
                        };
                        HistoricoAcessoService.salvar(historicoAcesso);
                        this.$router.push("/");
                    }
                },
                (error) => {

                    console.log(error);

                    this.loading = false;
                    if (error.response.data != null) {
                        this.message = error.response.data.message_error;
                    } else {
                        this.message = "Sistema indisponível";
                    }
                }
            );
        },

        erroColaborador(error) {
            this.message = error;
        },

        abrirRecuperarSenhaModal() {
            this.mostrarRecuperarSenhaModal = true;
        },

        fecharModalRecuperarSenha() {
            this.mostrarRecuperarSenhaModal = false;
        },

        abrirNossasUnidadesModal() {
            this.mostrarNossasUnidadesModal = true;
        },

        fecharNossasUnidadesModal() {
            this.mostrarNossasUnidadesModal = false;
        },

        getIp() {
            fetch('https://api.ipify.org?format=json')
                .then(x => x.json())
                .then(({ ip }) => {
                    this.ip = ip;
                });
        },

        callback(tokenCaptcha) {
            this.tokenCaptcha = tokenCaptcha;
        },

        abrirToast(tipo, titulo, descricao, tempo) {
            this.$toast.add({
                severity: tipo,
                summary: titulo,
                detail: descricao,
                life: tempo
            });
        },

        // async recuperarImagemFundo(){
        //     let Imagem = await ImagemFundoService.recuperarImagemFundo("site");

        //     console.log("Imagem: ", Imagem);
        //     console.log("base64", Imagem.data.base64);
        //     this.img = Imagem.data.base64;
        // },

        recuperarImagemFundo() {
            ImagemFundoService.recuperarImagemFundo("site")
                .then(
                    (response) => {

                        //console.log("Imagem: ", response);
                        //console.log("base64", response.data.base64);
                        //console.log("data", response.data);

                        let IMAGEM = response.data;

                        console.log("IMAGEM:", IMAGEM.nome);
                        this.img = response.data.base64;
                    },
                    (error) => {
                        console.error("Erro ao recuperar a imagem de fundo: ", error);
                    }
                );
        },
    },
};
</script>

<style scoped>
.label a {
    color: rgba(70, 130, 180) !important;
}

span {
    font-weight: bold;
}

.login-card {
    background: rgb(76, 146, 83) !important;
    box-shadow: -5px 5px 0 rgb(0, 0, 0, 0.6);
    -webkit-transition: box-shadow ease-out 0.8s;
    transition: box-shadow ease-out 0.8s;
    padding: 15px;
    border-radius: 10px;
    align-items: center;
}

.dv-login {
    border: solid 1px #cecece;
    background-color: #fbfbfb !important;
    padding: 15px;
    border-radius: 10px;
    align-items: center;
    margin-bottom: 10px !important;
}

.login-banner {
    border: solid 1px rgb(76, 144, 190) !important;
    border-radius: 10px 10px 0px 0px;
    background: rgb(76, 144, 190);
    padding: 10px 5px 0px 0px;
    text-align: center;
    color: #fff;
    font-size: 15px;
}

.login-content {
    border-radius: 0px 0px 10px 10px;
    background: #fff;
}

.profile-img-card {
    display: block;
    margin: 35px auto;
}

.p-button {
    display: block;
    margin: 0 auto;
    width: 100%;
    border: solid 1px rgb(76, 146, 83, 0.9) !important;
    background: rgba(76, 146, 83) !important;
}

.p-button:hover {
    border: solid 1px rgb(76, 146, 83, 0.8) !important;
    background: rgb(76, 146, 83, 0.8) !important;
    color: rgba(0, 0, 0);
}

.alert-danger {
    color: rgb(250, 6, 6);
    font-weight: bold;
    margin-top: 15px !important;
    margin-bottom: 10px !important;
}

.pages-body.login-page .pages-panel .pages-header {
    background: rgb(76, 146, 83) !important;
    max-width: 400px !important;
    ;
}

.dv-cadastro {
    background: hsl(53deg 100% 61% /77%);
    text-align: left;
}

.dv-resgate-senha {
    text-align: left;
}

.dv-resgate-senha a,
.dv-unidade a {
    cursor: pointer;
}

.dv-site {
    text-align: right;
}

.dv-unidade {
    text-align: right;
}

.dv-cadastro a {
    color: #000;
    font-weight: 800;
}

.pages-body .pages-panel {
    opacity: 0.9 !important;
    max-width: 500px !important;
}

.img-app-download {
    width: 130px !important;
}

.dv-google {
    text-align: right !important;
}

.dv-app-store {
    text-align: left !important;
}
</style>
